const Routes = {
    home: "/",
    calendar: "/calendar",
    activityPage: "/activity/:id",
    contact: "/contact",
    bulletin: {
        default: "/bulletin",
        ongoing: "/bulletin?section=ongoing",
        resources: "/bulletin?section=resources",
    },
    auth: {
        signOut: "/auth/signout",
        selectCampusForm: "https://forms.gle/wuwE5cYTwvNJ2H5P9",
    },
    admin: {
        newActivityPage: "/create/activity",
        reviewActivities: "/admin/review",
        studentListView: "/superadmin",
        parkingLot: "https://docs.google.com/document/d/1SvLXfjixhwAoLBqC4N7i5y4J8qt83-mqr8uQBXpT8oM/edit?usp=sharing"
    },
    external: {
        newActivityForm: "https://docs.google.com/forms/d/e/1FAIpQLSf_AZ4R05XXEaw4NtX5euS8V1e__RjWjrIAyDoQ9OpNAJLIpA/viewform",
        submitWolverscreenAd: "https://docs.google.com/document/d/1-2WFnxDsz7F4k1g78zXFa43rQeGigjwj2Hw6WYlRBMo/edit?usp=sharing",
        signUpClubsForm: "https://forms.gle/Sc7vnqu7PrPdnwpF6"
    }
};

export default Routes;