import React from 'react';
import { Videocam, Language, FormatListNumbered, InsertDriveFile, Visibility, SportsEsports, LiveTv, Share } from '@material-ui/icons';

const colors = {
    "zoom-blue": "#2D8CFF",
    "youtube-red": "#c20000",
    "netflix-red": "#E50914",
    "xbox-green": "#107C10",
    "chill-teal": "#096e64",

    "other": "#ededed",

    // 

    "read-more": "#6b6b6b"
}

const icons = {
    "video": <Videocam fontSize="small" />,
    "google-drive": <InsertDriveFile fontSize="small" />,
    "form": <FormatListNumbered fontSize="small" />,
    "game": <SportsEsports fontSize="small" />,
    "live-tv": <LiveTv fontSize="small" />,
    "share" : <Share fontSize="small" />,
    
    "other": <Language fontSize="small" />,

    // 

    "read-more": <Visibility fontSize="small" />
}

export { colors, icons }