import React, { Component } from 'react';
import { auth, db, analytics } from '../components/firebase';
import { ClipLoader } from 'react-spinners';
import moment from 'moment';
import Routes from '../components/routes';

class SelectCampusPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            campus: typeof this.props.userCampus === "undefined" ? null : this.props.userCampus,
            campusSelected: typeof this.props.userCampus !== "undefined",
            showError: false,
            user: null,
            submit: false,
        }
        console.log(this.props.userCampus);
        
    }

    async componentDidMount() {
        this.setState({ user: await auth.currentUser });
    }

    handleCampusSelection = (campus) => {
        this.setState({ campus: campus, campusSelected: true, showError: false });
    }

    handleSubmit = async () => {
        if (this.state.campusSelected === false || (this.state.campus !== "MS" && this.state.campus !== "US")) {
            this.setState({ showError: true })
            return
        }

        if (this.state.user === null || this.state.submit) {
            window.location.reload()
        }

        this.setState({ submit: true })

        const user = this.state.user

        analytics.logEvent("campus_selected", {
            campus: this.state.campus
        })

        await db.collection("users").doc(this.props.uid).set({
            timestamp: moment().unix(),
            displayName: user.displayName,
            email: user.email,
            photoURL: user.photoURL,
            phoneNumber: user.phoneNumber,
            campus: this.state.campus,
            campusReviewed: false,
            requestingCampusSwitch: false,
            searchTerms: this.createSearchTerms(user.displayName),
        })

        const currentUrl = "/" + window.location.href.split("/").splice(3).join("/")

        if (currentUrl === Routes.auth.selectCampus) {
            window.location.href = Routes.home
        } else {
            window.location.reload();
        }
    }

    createSearchTerms = (name) => {
        const arrName = [];
        let curName = '';
        name.toLowerCase().split('').forEach((letter) => {
          curName += letter;
          arrName.push(curName);
        });
        return arrName;
      }

    render() {
        return (
            <div className="bg-graphics min-h-screen">
                <div className="max-w-3xl px-6 mx-auto pt-10 pb-20">
                    <div className="mb-20">
                        <p className="leading-tight text-4xl md:text-6xl font-semibold">Select your campus.</p>
                        <p className="leading-tight text-xl text-xl mt-2 text-gray-500">Choose your Harvard-Westlake campus and we'll optimize your experience on The Quad.</p>
                    </div>

                    <div className="md:flex md:-mx-6 md:my-0 -my-8">
                        <div className={"my-8 md:my-0 flex-1 md:mx-6 bg-white up-on-hover up-on-focus transition rounded-lg cursor-pointer" + (this.state.campus === "MS" ? " shadow-xl" : " shadow hover:shadow-md")} onClick={() => this.handleCampusSelection("MS")}>
                            <div>
                                <img className="bg-darken min-w-full rounded-t-lg" src="https://www.hw.com/Portals/28/Images/mscampusa.jpg?ver=2017-09-13-151432-823" alt="Lower school campus" />
                            </div>
                            <div className="p-5">
                                <p className={"font-semibold text-3xl leading-tight transition" + (this.state.campus === "MS" || !this.state.campusSelected ? " text-gray-800" : " text-gray-500")}>Middle School</p>
                                <p className="font-light text-sm mt-1 leading-tight">Grades 7-9</p>
                            </div>
                        </div>

                        <div className={"my-8 md:my-0 flex-1 md:mx-6 bg-white up-on-hover up-on-focus transition rounded-lg cursor-pointer" + (this.state.campus === "US" ? " shadow-xl" : " shadow hover:shadow-md")} onClick={() => this.handleCampusSelection("US")}>
                            <div>
                                <img className="min-w-full rounded-t-lg" src="https://www.hw.com/Portals/28/Images/uscampus6.jpg?ver=2017-09-05-150722-380" alt="Upper school campus" />
                            </div>
                            <div className="p-5">
                                <p className={"font-semibold text-3xl leading-tight transition" + (this.state.campus === "US" || !this.state.campusSelected ? " text-gray-800" : " text-gray-500")}>Upper School</p>
                                <p className="font-light text-sm mt-1 leading-tight">Grades 10-12</p>
                            </div>
                        </div>
                    </div>

                    <div className="mt-10 pt-5 md:pt-0">
                        <button onClick={this.handleSubmit} className={"flex items-center justify-center px-4 py-2 text-lg rounded-md text-white transition w-32 h-12" + (this.state.campusSelected || this.state.submit ? " btn-campus-on up-on-hover" : " btn-campus-off")}>
                            {!this.state.submit ? "Select" : (
                                <ClipLoader size={20} color="white" />
                            )}
                        </button>

                        {!this.state.showError ? "" : (
                            <p className="color-primary-red text-sm leading-tight mt-2">You must select a campus.</p>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default SelectCampusPage;