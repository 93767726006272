import React from 'react';
import Calendar from '../components/Calendar';
import EmptyNavbar from '../components/EmptyNavbar';


const CalendarPage = ({ }) => {
    return (
        <>
            <EmptyNavbar />

            <div className="px-6 max-w-3xl mx-auto mt-4 pb-20">
                <Calendar view="list" eventClicked={(title, event) => {
                    console.log(title, event);
                }}/>
            </div>
        </>
    );
}

export default CalendarPage;