import React, { Component } from 'react';
import moment from 'moment'
import { db, analytics } from './firebase';
import FullCalendar from '@fullcalendar/react'

// CALENDAR 

import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'

import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';
import '@fullcalendar/list/main.css';

class Calendar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            events: null,
        }

        this.getActivities()

        analytics.logEvent("calendar_page");
    }

    getActivities = async (callback) => {
        const events = []
        let querySnapshot = await db.collection("activities").where("reviewed", "==", true).get()

        querySnapshot.forEach((docRef) => {
            const activity = docRef.data()
            activity.id = docRef.id

            if (activity.liveActivity) {
                const eventToAdd = {
                    start: moment.unix(activity.timestamp.start).toDate(),
                    end: moment.unix(activity.timestamp.end).toDate(),
                    title: activity.title,
                    description: activity.description,
                    url: "/activity/" + activity.id
                }

                events.push(eventToAdd)
            }
        })

        this.setState({ events })
    }

    render() {
        // if (this.state.events == null) {
        //     return ""
        // }

        let defaultView = "dayGridMonth";
        if (this.props.view === "list") {
            defaultView = "list"
        }

        return (
            <div>

                <FullCalendar

                    eventColor="#c60000"

                    views={{
                        timeGridWeek: {
                            displayEventTime: false,
                        },
                        list: {
                            duration: { days: 90 },
                        }
                    }}

                    scrollTime="10:00:00"

                    header={{
                        left: "today prev,next",
                        center: "title",
                        right: "dayGridMonth,timeGridWeek,timeGridDay,list"
                    }}

                    editable={true}

                    eventLimit={true}

                    eventClick={(event) => {
                        // if(this.props.view === "list") {
                        //     this.props.eventClicked(event.event.title, event.event._def.extendedProps);
                        // } else {
                        //     window.location.href = event.path
                        // }
                    }}

                    events={this.state.events == null ? "" : this.state.events}

                    defaultView={defaultView}

                    loading={this.state.events == null}

                    plugins={[dayGridPlugin, timeGridPlugin, listPlugin]} />

            </div>
        );
    }
}

export default Calendar;