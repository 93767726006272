import React, { Component } from "react";
import { analytics } from "../components/firebase";
import logo from "../assets/hw-box.jpg";
import { Tooltip } from "react-tippy";
import Routes from "../components/routes";
import { Person } from "@material-ui/icons/";

class SignInPage extends Component {
  constructor(props) {
    super(props);

    analytics.logEvent("signin_page");
  }

  render() {
    return (
      <div className="bg-quad bg-fixed top-0 right-0 left-0 bottom-0 h-screen w-screen flex items-center justify-center">
        <div className="max-w-xl px-6 w-full">
          <div className="bg-gray-50 px-5 md:px-10 rounded-lg py-12 shadow-2xl">
            <div className="mb-10 text-center">
              <div className="flex justify-center mb-12 flex-row items-start">
                <img className="w-64 md:max-w-xs" src={logo} alt="logo" />
              </div>

              <p className="text-5xl font-semibold title text-gray-800 tracking-wider leading-tight brand">
                The Quad
              </p>
              <p className="flex font-light justify-center mx-auto text-gray-500 leading-tight mt-3 max-w-xs">
                Uniting the Harvard-Westlake community via the web.
              </p>
            </div>
            <div className="mb-12">
              <p className="text-2xl text-gray-600 leading-tight text-center">
                Sign in.
              </p>
            </div>

            <div className="shadow-lt p-8 rounded-lg">
              <button
                className="up-on-hover transition hover:shadow focus:shadow w-full border-2 rounded-md border-gray-800 text-gray-800 px-4 py-2 font-medium bg-white flex items-center justify-center"
                onClick={this.props.signIn}
              >
                <img
                  alt="HW logo"
                  src="https://pbs.twimg.com/profile_images/907675981388775424/aTICyCiW.jpg"
                  className="w-6 h-6"
                />
                <span className="ml-2">Sign in with HW</span>
              </button>
              <a
                href={Routes.calendar}
                className="mt-3 up-on-hover transition hover:shadow focus:shadow w-full border-2 rounded-md border-gray-300 text-gray-600 hover:text-gray-800 px-4 py-2 font-medium bg-white flex items-center justify-center"
                onClick={this.props.signIn}
              >
                <Person />
                <span className="ml-2">I'm a guest</span>
              </a>
              {/* <p className="text-gray-500 mt-3 text-sm">I understand that my actions are associated with my Harvard-Westlake identity.</p> */}
            </div>

            <div className="mt-8 text-center">
              <p className="text-gray-500 text-sm">
                Questions? Email{" "}
                <Tooltip
                  trigger="click"
                  size="small"
                  position="bottom"
                  distance="10"
                  title="airfan2@hwemail.com"
                >
                  <span className="text-gray-700 cursor-pointer up-on-hover">
                    Aariz Furniturewala '23
                  </span>
                </Tooltip>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SignInPage;
