import React, { Component } from 'react';
import LinkList from './LinkList';
import moment from "moment";
import { FiberManualRecord, Add, Check } from '@material-ui/icons';
import { functions, analytics, db } from './firebase';
import { isAdmin } from "../helpers"
import ClipLoader from "react-spinners/ClipLoader";
import ShowMoreText from 'react-show-more-text';
import { toast } from 'react-toastify';

class Activity extends Component {
    constructor(props) {
        super(props);
        this.state = {
            signedUp: this.props.activity.signedUp
        }
    }

    handleSignUpClicked = async () => {
        const prevSignedUpState = this.state.signedUp;
        const newSignedUpState = !this.state.signedUp;

        this.setState({ signedUp: newSignedUpState });

        await functions.httpsCallable("signUpForActivity")({
            activityId: this.props.activity.id,
            signedUp: prevSignedUpState
        }).then(() => {
        })

        analytics.logEvent("user_signed_up", {
            uid: this.props.uid,
            activityId: this.props.activity.id
        });
    }

    render() {
        const activity = this.props.activity

        switch (this.props.type) {
            case "live":
                return <LiveActivity handleSignUpClicked={this.handleSignUpClicked} signedUp={this.state.signedUp} activity={activity} />
            case "upcoming":
                return <UpcomingActivity handleSignUpClicked={this.handleSignUpClicked} signedUp={this.state.signedUp} activity={activity} />
            case "page":
                return <SingleActivity handleSignUpClicked={this.handleSignUpClicked} signedUp={this.state.signedUp} uid={this.props.uid} activity={activity} />
            default:
                break
        }
    }
}

const LiveActivity = (props) => {
    const activity = props.activity

    return (
        <div className="border border-gray-200 transition bg-white shadow rounded-lg p-5 h-full flex flex-col">

            <div>
                <p className="text-lg leading-tight text-gray-700">{activity.title}</p>
                <p className="text-sm text-gray-600 font-light">by {activity.host.name}</p>
            </div>


            <div className="flex-grow flex flex-col h-full mt-2">
                <div className="flex-grow"></div>

                <div>
                    <p className="text-sm font-light text-gray-500 leading-tight mb-2">
                        ends {moment.unix(activity.timestamp.end).fromNow()}
                    </p>
                    <LinkList links={activity.links} size={"small"} activityId={activity.id} />
                </div>
            </div>
        </div>
    )
}

const UpcomingActivity = (props) => {
    const activity = props.activity

    return (
        <div className="border rounded-lg border-gray-200 shadow-lt transition bg-white">

            <div className="md:flex items-stretch">
                <div className="px-3 md:px-6 flex-1 py-1 border-b border-gray-200 items-center flex">
                    <p className="font-light text-gray-600">{moment.unix(activity.timestamp.start).format("MMM Do h:mm A")} to {moment.unix(activity.timestamp.end).format("MMM Do h:mm A")}</p>
                </div>

                <button onClick={props.handleSignUpClicked} className={"w-full md:w-auto border-b border-l border-gray-200 up-on-hover px-6 py-4 bg-white flex transition flex items-center text-sm md:rounded-tr-lg" + (props.signedUp ? " btn-signup text-white" : " hover:bg-gray-100 color-primary-red -gray-900")}>
                    {!props.signedUp ? <Add fontSize="small" /> : <Check fontSize="small" />}
                    <span className="ml-1">I'm interested</span>
                </button>


            </div>


            <div className="w-full">
                <div className="px-3 md:px-6 pt-3 pb-3 md:pb-6 flex-1">
                    <div>
                        <p className="text-xl md:text-2xl text-gray-700 leading-tight mt-1">{activity.title}</p>
                        <span className="text-sm text-gray-600 font-light mb-3">by  {activity.host.name}</span>
                        <p className="font-light leading-tight mt-3 text-gray-500">
                            <ShowMoreText>
                                {activity.description}
                            </ShowMoreText>
                        </p>
                    </div>

                    <div className="mt-5">
                        <LinkList size={"normal"} links={activity.links} activityId={activity.id} />
                    </div>
                </div>
            </div>
        </div>
    );
}

class SingleActivity extends Component {
    constructor(props) {
        super(props);
        this.state = {
            admin: null,
            isQuadAdmin: null,
            students: null,
            showDeleteView: false,
            deleteInputValue: "",

        }

        analytics.logEvent("activity_page", {
            uid: this.props.uid,
            activityId: this.props.activity.id
        });
    }

    async componentDidMount() {
        let admin = await isAdmin(this.props.uid)

        this.setState({ isQuadAdmin: admin })

        // if (!admin) {
        if (!admin) {
            const res = await functions.httpsCallable("getStudentData")();
            const email = res.data.email;

            admin = (email === this.props.activity.host.email)
        }

        this.setState({ admin });

        if (admin) {
            const res = await functions.httpsCallable("getStudentsData")({ studentUids: this.props.activity.students })
            const students = res.data
            this.setState({ students, admin })
        }
    }

    approveActivity = async () => {
        let confirmation = window.confirm("Are you sure you want to approve this activity?")
        if (!confirmation) return;

        toast.info("Loading...", {
            autoClose: 10000
        })

        const res = await functions.httpsCallable("approveActivity")({ activity: this.props.activity })

        toast.dismiss()
        toast.success("Success. The activity was approved.")
    }

    rejectActivity = async () => {
        let reason = window.prompt("Please provide a BRIEF reason for rejection.")
        if(reason.trim() === "") {
            toast.info("Please provide a valid reason.")
            return;
        }

        toast.info("Loading...", {
            autoClose: 10000
        })

        const res = await functions.httpsCallable("rejectActivity")({ activity: this.props.activity, reason: reason })

        toast.dismiss()
        toast.success("Success. The activity was rejected.")
    }

    render() {
        const activity = this.props.activity
        const nowUnix = moment().unix()

        const campuses = []
        if (activity.upperSchoolActivity) campuses.push("Upper School")
        if (activity.middleSchoolActivity) campuses.push("Middle School")

        return (
            <div className="">
                {!(nowUnix < activity.timestamp.end && nowUnix > activity.timestamp.start) ? "" :
                    <div className="py-2 w-full bg-primary-red">
                        <div className="blink-me text-white text-xl font-semibold uppercase tracking-widest flex items-center justify-center">
                            <FiberManualRecord fontSize="small" />
                            <span className="ml-2">Live</span>
                        </div>
                    </div>
                }



                <div className="mt-4 max-w-3xl px-6 mx-auto pb-20">
                    {activity.reviewed ? null : (
                        <div className="rounded-lg p-3 bg-red-600 mb-10 flex items-center">
                            <p className="text-white font-semibold flex-1">This has not been approved by an admin yet. Do not share this URL.</p>

                            {this.state.admin == null || !this.state.isQuadAdmin ? null : (
                                <div className="">
                                    <button className="rounded-lg px-3 py-2 bg-white text-green-600 mr-3 font-semibold" onClick={this.approveActivity}>Approve</button>
                                    <button className="rounded-lg px-3 py-2 bg-white text-red-700 font-semibold" onClick={this.rejectActivity}>Reject</button>
                                </div>
                            )}
                        </div>
                    )}



                    {!activity.reviewed ? null : (
                        <div className="flex justify-end">
                            <button onClick={this.props.handleSignUpClicked} className={"transition mb-2 hover:shadow-lg bg-gray-100 up-on-hover font-light flex items-center shadow px-8 py-4 rounded" + (this.props.signedUp ? " btn-signup text-white" : " bg-gray-100 hover:bg-gray-200 text-gray-900")}>
                                {!this.props.signedUp ? <Add /> : <Check />}
                                <span className="ml-1">I'm interested</span>
                            </button>
                        </div>
                    )}



                    <div className="mt-4">
                        <p className="text-xl text-gray-600 font-light leading-tight">{moment.unix(activity.timestamp.start).format("MMM Do h:mm A")} to {moment.unix(activity.timestamp.end).format("MMM Do h:mm A")}</p>
                    </div>

                    <div className="mt-3">
                        <p className="text-2xl md:text-3xl font-semibold leading-tight">{activity.title}</p>

                        {activity.reviewed ? null : (
                            <p className="font-semibold">For: {campuses.join(", ")} </p>
                        )}
                    </div>

                    <p className="text-lg text-gray-500 mt-3 font-light">{activity.description}</p>

                    <div className="mt-5">
                        <LinkList readMore={false} size={"normal"} links={activity.links} activityId={activity.id} />
                    </div>

                    <div className="mt-4">
                        <p className="justify-end flex text-gray-500 leading-tight font-light text-lg">
                            Hosted by {activity.host.name} {!this.state.admin ? "" : "(" + activity.host.email + ")"}
                        </p>
                        <p className="mt-1 text-right text-gray-500 leading-tight font-light">Posted {moment.unix(activity.timestamp.created).fromNow()}</p>
                    </div>

                    {!(this.state.admin && this.state.students == null) ? "" : (
                        <div className="justify-center flex mt-8">
                            <ClipLoader />
                        </div>
                    )}

                    {this.state.students == null || !activity.reviewed ? "" : (
                        <div className="mt-10 p-4 md:p-8 rounded-lg bg-gray-100">
                            <div className="">
                                <div className="flex justify-start">
                                    <div className="">
                                        <button className="bg-red-600 hover:bg-red-800 text-white rounded-md px-4 py-2 transition" onClick={() => this.setState({ showDeleteView: !this.state.showDeleteView })}>Delete activity</button>
                                        {!this.state.showDeleteView ? "" : (
                                            <div className="mt-3 p-5 bg-gray-300 rounded-md shadow-lt max-w-xs">
                                                <input onChange={(e) => this.setState({ deleteInputValue: e.target.value })} placeholder={"Type host name"} value={this.state.deleteInputValue} className="text-xs flex transition rounded-md bg-gray-100 focus:bg-white px-4 py-2" />
                                                <button className={"flex px-4 py-2 rounded-md w-full text-white font-semibold uppercase transition text-xs mt-2 text-center flex justify-center" + (this.state.deleteInputValue.trim() === activity.host.name.trim() ? " bg-black" : " cursor-not-allowed bg-gray-200")}
                                                    onClick={async () => {
                                                        if (this.state.deleteInputValue.trim() !== activity.host.name.trim()) return
                                                        this.setState({ deleteInputValue: "" })
                                                        await db.collection("activities").doc(activity.id).delete()

                                                        toast.success("Success. Activity deleted. Please reload.", { position: toast.POSITION.BOTTOM_LEFT })
                                                    }}>
                                                    Delete activity
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <p className="text-xl text-gray-700 mt-5">Admin view.</p>
                                <p className="leading-tight text-gray-500">Do not share this information with anyone. List is not realtime.</p>
                            </div>
                            <div className="mt-6">
                                <p className="text-gray-600">
                                    <span className="text-lg text-gray-800">{this.state.students.length}</span> student{this.state.students.length !== 1 ? "s" : ""} so far
                                </p>
                            </div>
                            {this.state.students.length < 1 ? "" : (
                                <div className="rounded-lg border mt-3">
                                    <div className="bg-gray-200 px-4 py-3 flex">
                                        <p className="w-1/2 uppercase font-semibold text-sm text-gray-600">
                                            Display name
                                    </p>
                                        <p className="w-1/2 uppercase font-semibold text-sm text-gray-600">
                                            Email
                                    </p>
                                    </div>
                                    {this.state.students.map((student, i) => {
                                        return student == null ? "" : (
                                            <div key={i} className="flex items-end px-4 py-2 border-t bg-white">
                                                <p className="font-semibold w-1/2 text-gray-700 items-center">
                                                    <span className="text-xs text-gray-500 font-bold mr-2">{i + 1}</span>
                                                    <span>{student.displayName}</span>
                                                </p>
                                                <p className="text-gray-600 text-light md:text-base w-1/2">
                                                    {student.email}
                                                </p>
                                            </div>
                                        )
                                    })}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div >
        )
    }
}


export default Activity;