import React, { Component } from 'react';
import Link from './Link';
import moment from 'moment';

class LinkList extends Component {
    
    render() {
        const nowUnix = moment().unix()
        const currentUrl = window.location.href.split("/").slice(0, 3).join("/")

        const viewMoreLink = {
            url: currentUrl + "/activity/" + this.props.activityId,
            color: "read-more",
            type: "read-more",
            name: "Read more",
            expiration: moment(new Date("01/01/2100")).unix(),
            newTab: false
        }

        const shareLink = {
            color: "chill-teal",
            type: "share",
            name: "Share activity",
            expiration: moment(new Date("01/01/2100")).unix(),
            newTab: false,
            share: true,
            activityId: this.props.activityId
        }

        const links = this.props.links;
        // links.splice(0, 0, shareLink)
        // links.push(shareLink)

        return (
            <div className="-mx-1 sm:flex">
                {!(this.props.readMore !== false) ? "" :
                    <div className="mx-1 my-2 sm:my-0">
                        <Link link={viewMoreLink} size={this.props.size} />
                    </div>
                }

                <div className="mx-1 my-2 sm:my-0">
                    <Link link={shareLink} size={this.props.size} />
                </div>

                {links.map((link, i) => {
                    return nowUnix > link.expiration ? "" : (
                        <div key={i} className="mx-1 my-2 sm:my-0">
                            <Link link={link} size={this.props.size} />
                        </div>
                    )
                })}
            </div>
        );
    }
}

export default LinkList;