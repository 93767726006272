import React, { Component } from 'react';
import { colors, icons } from './linkConstants';
import { analytics, db, functions, auth } from './firebase';
import { Tooltip } from 'react-tippy';
import { Search, ArrowRightAlt } from '@material-ui/icons';
import { ClipLoader } from 'react-spinners'
import { toast } from 'react-toastify';

class Link extends Component {
    constructor(props) {
        super(props)
        this.state = {
            open: false
        }
    }

    openLink = (url) => {
        analytics.logEvent("link_opened", {
            type: this.props.link.type,
            name: this.props.link.name,
            url: this.props.link.url,
            color: this.props.link.color
        })

        if (this.props.link.newTab) {
            window.open(url);
        } else if (this.props.link.share) {
            this.setState({ open: !this.state.open })
        } else {
            window.location.href = url
        }
    }

    hideShareView = () => {
        this.setState({ open: false })
    }

    render() {

        const link = this.props.link

        const activityId = link.activityId;

        const customStyle = {
            backgroundColor: colors[link.color],
        };

        return (
            <Tooltip
                unmountHTMLWhenHide="true"
                position="bottom"
                arrow="true"
                open={this.state.open}
                animation="fade"
                trigger={link.share ? "click" : "manual"}
                theme="light"
                interactive="true"
                html={<ShareActivity activityId={activityId} close={this.hideShareView} />}>

                <button onClick={() => this.openLink(link.url)} className={"up-on-hover flex font-medium items-center rounded hover:shadow-md transition cursor-pointer" + (link.color === "other" ? " text-gray-700" : " text-white") + (this.props.size === "small" ? " py-px px-1 text-xs" : " py-1 px-2 text-sm")} style={customStyle}>
                    {icons[link.type]}
                    <span className="ml-2">
                        {link.name}
                    </span>
                </button>
            </Tooltip>
        );
    }
}

class ShareActivity extends Component {
    constructor(props) {
        super(props);
        this.state = {
            input: "",
            students: null,
            studentSelected: null,
            loaded: false,
            submitted: false
        }
    }

    selectStudent = (student) => {
        this.setState({
            studentSelected: student,
            input: ""
        })
    }

    submit = async () => {
        if (this.state.studentSelected == null || typeof this.state.studentSelected.displayName === "undefined") return

        const activityId = this.props.activityId

        this.setState({ submitted: true })

        analytics.logEvent("activity_shared", {
            uid: await auth.currentUser.uid,
            activityId: activityId,
        })

        try {
            await functions.httpsCallable("shareActivity")({
                activityId: activityId,
                toEmail: this.state.studentSelected.email,
                invitedBy: await auth.currentUser.displayName
            })

            toast.success("Success. Invite sent to " + this.state.studentSelected.displayName + " (" + this.state.studentSelected.email + ").", { position: toast.POSITION.BOTTOM_LEFT })

        } catch (e) {
            toast.error("There was an error. Try again.", { position: toast.POSITION.BOTTOM_LEFT })
        }

        this.props.close();
    }

    search = async () => {
        this.setState({ loaded: false, studentSelected: null })

        const query = await db.collection("users").where("searchTerms", "array-contains", this.state.input.toLowerCase()).limit(3).get();
        const students = []
        query.forEach(doc => {
            students.push(doc.data())
        });
        this.setState({ students, loaded: true })
    }

    inputChanged = (e) => {
        if (e.target.value.trim() === "") {
            this.setState({ input: e.target.value, students: null })
            return
        }

        this.setState({
            input: e.target.value,
            // students: null,
            loaded: false,
            studentSelected: null
        }, () => {
            this.search()
        })
    }

    render() {
        return (
            <div className="p-1">
                <div className="p-2 border flex">
                    <input className="flex-1" placeholder="First name" value={this.state.input} onChange={this.inputChanged} />
                    <button className="ml-2 text-gray-800 hover:text-black up-on-hover transition" onClick={this.search}>
                        <Search fontSize="small" />
                    </button>
                </div>

                {this.state.students == null ? "" : (
                    <div className="">
                        {this.state.students.length === 0 && this.state.loaded ? (
                            <div className="text-gray-500 mt-2">
                                No users found
                            </div>
                        ) : (
                                <div className="mt-2">{this.state.students.map((student) => {
                                    return (
                                        <button onClick={() => this.selectStudent(student)} className={"p-1 rounded-md justify-start w-full flex transition text-gray-600 bg-gray-100 my-1" + (this.state.studentSelected === student ? " bg-gray-300 text-gray-800" : " hover:text-gray-800 hover:bg-gray-200")}>

                                            <p className="font-medium text-sm flex-1">{student.displayName}</p>

                                        </button>
                                    )
                                })}
                                </div>
                            )}
                    </div>
                )}

                {this.state.studentSelected === null ? "" : (
                    <div className="mt-3">
                        <button className="rounded-md px-3 h-10 bg-gray-900 hover:bg-black text-white flex items-center justify-center font-semibold transition w-full text-sm text-white grow-on-hover" onClick={this.submit}>
                            {!this.state.submitted ? (<div>
                                Send to {this.state.studentSelected.displayName.split(" ")[0]}
                                <ArrowRightAlt fontSize="small" />
                            </div>) :
                                <ClipLoader size={20} color="#fff" />
                            }
                        </button>
                    </div>
                )}
            </div>
        );
    }
}


export default Link;