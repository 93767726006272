import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/auth';
import 'firebase/performance';
import 'firebase/analytics';

const config = {
    apiKey: "AIzaSyCrdqbFrn6qPtuVbPR2ChUSN_2ulrOPRfY",
    authDomain: "hw-online-activities.firebaseapp.com",
    databaseURL: "https://hw-online-activities.firebaseio.com",
    projectId: "hw-online-activities",
    storageBucket: "hw-online-activities.appspot.com",
    messagingSenderId: "42756310066",
    appId: "1:42756310066:web:8536336358d3d288a8e82b",
    measurementId: "G-LPYT527BVH"
};

firebase.initializeApp(config);

export const db = firebase.firestore();
export const auth = firebase.auth();
export const functions = firebase.functions();
export const provider = new firebase.auth.GoogleAuthProvider();
export const analytics = firebase.analytics();
export const performance = firebase.performance();

export default firebase;