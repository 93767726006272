import React, { Component } from 'react';
import Navbar from '../components/Navbar';
import { KeyboardBackspace } from '@material-ui/icons';
import { analytics } from "../components/firebase"
import Routes from '../components/routes';

class ErrorPage extends Component {
    constructor(props) {
        super(props)
        analytics.logEvent("error_page", {
            type: this.props.type
        });
    }

    handleClick = () => {
        if (this.props.type === "not-hw") {
            this.props.parentSetState({ userIsHW: null })
        } else {
            window.location.href = Routes.home
        }
    }

    render() {
        let errorText;
        let buttonText = "Return"

        switch (this.props.type) {
            case "admin":
                errorText = "You are not an authorized admin."
                break
            case "not-hw":
                errorText = "Please sign in with your Harvard-Westlake email."
                buttonText = "Sign in"
                break
            case "activity_not_found":
                errorText = "The activity you are looking for cannot be found."
                break
            case "404":
                errorText = "404. We can't find the page."
                break
            default:
                break
        }

        return (
            <div>
                <Navbar />

                <div className="py-10 min-h-screen h-full">
                    <div className="max-w-3xl mx-auto px-6">
                        <div className="shadow-2xl rounded-lg bg-white p-8">
                            <button onClick={this.handleClick} className="flex items-center text-gray-500 hover:text-gray-800 focus:text-gray-800 transition font-medium">
                                <KeyboardBackspace fontSize="small" />
                                <span className="ml-1">
                                    {buttonText}
                                </span>
                            </button>
                            <p className="text-4xl font-medium text-black leading-tight mt-2">Error.</p>
                            <p className="text-3xl font-medium text-gray-600 leading-tight mt-1">{errorText}</p>
                            <p className="text-center text-gray-500 mt-5">Questions? Concerns? Email Aariz Irfan (airfan2@hwemail.com)</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ErrorPage;