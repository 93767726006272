import React, { Component } from 'react';
import Navbar from '../components/Navbar';
import LoadingScreen from '../components/LoadingScreen';
import { db } from '../components/firebase';
import { Check, Clear } from '@material-ui/icons';
import Routes from '../components/routes';

class StudentListViewPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            students: null,
        }
    }

    async componentDidMount() {
        const superadmin = await (await db.collection("global").doc("admins").get()).data().superadmins.includes(this.props.uid);
        if (!superadmin) window.location.href = Routes.home

        this.getStudents()
    }

    getStudents = async () => {
        const students = []
        const query = await db.collection("users").where("campusReviewed", "==", false).get()

        query.forEach((doc) => {
            let data = doc.data()
            data.uid = doc.id;
            students.push(data)
        })

        this.setState({ students });
    }

    handleSelection = async (student, accept) => {
        const requesting = student.requestingCampusSwitch,
            uid = student.uid,
            campus = student.campus

        if (requesting) {
            await db.collection("users").doc(uid).update({
                campusReviewed: true,
                requestingCampusSwitch: false,
                campus: (campus === "MS") ? (accept ? "US" : "MS") : (!accept ? "US" : "MS")
            })
        } else {
            await db.collection("users").doc(student.uid).update({
                campusReviewed: true,
                campus: accept ? campus : (campus === "MS" ? "US" : "MS")
            })
        }

        let filtered = this.state.students.filter(function (obj) {
            return obj.uid !== student.uid;
        });

        this.setState({ students: filtered });
    }

    render() {
        if (this.state.students == null) {
            return <LoadingScreen />
        }

        return (
            <div className="min-h-screen">
                <Navbar />
                <div className="pt-10 max-w-3xl px-6 mx-auto">
                    <p className="font-semibold text-xl mb-4 text-gray-500">Verify student campus selections</p>

                    <div className="-my-4 pt-2">
                        {this.state.students.map((student) => {
                            return (
                                <div className="my-4 flex rounded-lg bg-gray-200 p-4 items-center">
                                    <div className="flex-1">
                                        <p className="">
                                            {student.displayName} <span className="text-gray-600">({student.email})</span> is in

                                            {student.campus === "MS" ?
                                                (!student.requestingCampusSwitch ? " Middle School" : " Upper School")
                                                :
                                                (student.requestingCampusSwitch ? " Middle School" : " Upper School")
                                            }
                                        </p>
                                    </div>

                                    <div className="-mx-3">
                                        <button className="p-1 mx-3 text-green-500 hover:text-green-700" onClick={() => this.handleSelection(student, true)}>
                                            <Check />
                                        </button>

                                        <button className="p-1 mx-3 text-red-500 hover:text-red-700" onClick={() => this.handleSelection(student, false)}>
                                            <Clear />
                                        </button>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        );
    }
}

export default StudentListViewPage;