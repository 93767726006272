const bulletinInfo = [
    {
        title: "Community service opportunities",
        url: "https://docs.google.com/document/d/1GiY-CAWjae31wIw03jFdoBcl-PzJoOigK6bi8YEZS6k/edit"
    },
    {
        title: "Mental Health Resources",
        url: "https://docs.google.com/document/d/1ZsDeXoJ_kzLQDtCY35SH5p1ysyJAFmWueZolWVU_aFw/edit"
    },
    {
        title: "An article from Ms. Bracken",
        url: "https://www.mindful.org/zoom-exhaustion-is-real-here-are-six-ways-to-find-balance-and-stay-connected/"
    },
    {
        title: "Check out the 73 questions YouTube video",
        url: "https://www.youtube.com/playlist?list=PLoPd6uQF_3fRyQ14waK5bqjPrU1YK8OTh"
    },
    {
        title: "Visit @Prefectagram, where Prefects will post fun material and updates",
        url: "https://www.instagram.com/prefectagram/"
    },
    {
        title: "Create or deepen a friendship by writing your pen pal",
        url: "https://forms.gle/RLqaKLBKsyyezpH76"
    },
    {
        title: "Read books recommended by your favorite teachers",
        url: "https://docs.google.com/document/d/19w3qTKO9hj3mhfgzKD4CfgKVarQSj93ykb6nYaQ6Abc/edit?usp=sharing"
    },
    {
        title: "Participate in the Spring Break TikTok Competition",
        url: "https://forms.gle/VfLLxY5NDxabp1jv9"
    },
]

export default bulletinInfo;