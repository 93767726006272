import React, { Component } from 'react';
import DateTime from "react-datetime";
import moment from "moment";

class TimeSelector extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: null,
            endDate: null
        }
    }

    handleChange = (start, date) => {
        this.setState({
            [start?"startDate" : "endDate"]: moment(date).unix()
        }, () => {
            this.props.setDates(this.state)
        })
    }

    render() {
        return (
            <div className="flex">
                <div className="w-1/2">
                    <p className="text-gray-600 text-xl">Start:</p>
                    <DateTime  onChange={(date) => this.handleChange(true, date)} />
                </div>
                <div className="w-1/2">
                    <p className="text-gray-600 text-xl">End:</p>
                    <DateTime onChange={(date) => this.handleChange(false, date)} />
                </div>
            </div>
        );
    }
}

export default TimeSelector;