import React, { Component } from 'react';
import Navbar from '../components/Navbar';
import { db, analytics } from '../components/firebase';
import LoadingScreen from "../components/LoadingScreen"
import Activity from "../components/Activity"
import { FiberManualRecord, ArrowRightAlt, CalendarTodayOutlined, CloseOutlined, ArrowDropDown } from '@material-ui/icons';
import moment from "moment";
import Routes from "../components/routes"
import Calendar from "../components/Calendar"
import { isMobile } from "react-device-detect"
import { Tooltip } from 'react-tippy';

class HomePage extends Component {
    constructor(props) {
        super(props)

        this.state = {
            upcomingActivities: null,
            liveActivities: null,
            activitiesShown: 10,
            showCalendar: false,
            studentUS: true
        }




        analytics.logEvent("home_page", {
            uid: this.props.uid
        });
    }



    getStudentCampus = async () => {
        const studentUS = await (await db.collection("users").doc(this.props.uid).get()).data().campus === "US"

        this.setState({ studentUS }, () => {
            this.getActivites()
        })
    }

    showCalendar = () => {
        this.setState({ showCalendar: true });
        document.body.style.overflow = 'hidden';

    };

    hideCalendar = () => {
        this.setState({ showCalendar: false });
        document.body.style.overflow = 'unset';

    };

    async componentDidMount() {
        window.addEventListener("scroll", this.handleScroll);

        this.getStudentCampus()
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll)
    }

    handleScroll = () => {
        const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
        const body = document.body;
        const html = document.documentElement;
        const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
        const windowBottom = windowHeight + window.pageYOffset;
        if (windowBottom >= docHeight) {
            this.setState({ activitiesShown: this.state.activitiesShown + 5 }, () => {
                this.getActivites()
            })
        }
    }

    getActivites = () => {
        const upcomingActivities = []
        const liveActivities = []
        const nowUnix = moment().unix()

        const userCampus = this.state.studentUS ? "upperSchoolActivity" : "middleSchoolActivity"

        db.collection("activities").where("liveActivity", "==", true).where(userCampus, "==", true).where("timestamp.end", ">", nowUnix).where("reviewed", "==", true).orderBy("timestamp.end").limit(this.state.activitiesShown).get().then(querySnapshot => {
            querySnapshot.forEach((doc) => {
                let activity = doc.data();
                activity.id = doc.id;
                activity.signedUp = activity.students.includes(this.props.uid)

                if (nowUnix < activity.timestamp.end && nowUnix > activity.timestamp.start && liveActivities.length < 3 && activity.liveActivity === true) {
                    liveActivities.push(activity)
                } else {
                    upcomingActivities.push(activity);
                }
            })

            // moment
            this.setState({ liveActivities, upcomingActivities })
        })
    }

    handlePostActivityClicked = () => {
        analytics.logEvent("post_activity_clicked", {
            uid: this.props.uid
        });

        window.location.replace(Routes.admin.newActivityPage)
    }

    render() {
        if (this.state.liveActivities == null) {
            return <LoadingScreen />
        }

        return (
            <div className=" min-h-screen flex flex-col">
                <Navbar />

                {!this.state.showCalendar ? "" :
                    <CalendarModal show={this.state.showCalendar} hideCalendar={this.hideCalendar} userCampus={this.props.userCampus} />
                }

                {this.state.showCalendar || isMobile ? "" : (
                    <div className="pb-10 pr-10 right-0 bottom-0 fixed">
                        <button onClick={this.showCalendar} className="px-6 py-4 rounded-full bg-calendar-btn text-white leading-tight flex items-center text-sm shadow-lg hover:shadow-lg transition">
                            <CalendarTodayOutlined fontSize="small" />
                            <span className="ml-2">View calendar</span>
                        </button>
                    </div>
                )}

                {this.state.liveActivities.length < 1 ? null :
                    (
                        <div className="pt-8 pb-10 md:pb-16 bg-primary-gray-dark">
                            <div className="px-6 mx-auto max-w-3xl">
                                <p className={"flex items-center uppercase text-lg font-bold tracking-widest color-primary-red" + (this.state.showCalendar ? "" : " blink-me")}>
                                    <FiberManualRecord fontSize="small" />
                                    <span className="ml-2">Live</span>
                                </p>

                                <div className="mt-4 sm:flex sm:-mb-4 -mx-3 items-stretch">
                                    {this.state.liveActivities.map((activity, i) => {
                                        return (
                                            <div key={i} className="my-4 sm:my-0 flex-1 mx-3  max-w-xl">
                                                <Activity uid={this.props.uid} type="live" activity={activity} />
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    )
                }


                <div className="w-screen bg-quad-cropped bg-darken">
                    <div className="h-full mx-auto px-6 max-w-3xl pt-16 pb-8">
                        <p className="text-3xl md:text-4xl brand leading-tight font-semibold text-white mt-1">Upcoming activities</p>
                        <p className="md:text-xl font-light text-gray-400 leading-tight mt-1">View the upcoming activities and events in the Harvard-Westlake community.</p>
                    </div>
                </div>

                <div className="flex justify-center bg-gray-lt flex-1">

                    <div className="pt-4 px-6 max-w-3xl w-full pb-20">

                        <div className="flex justify-end">
                            <button onClick={this.handlePostActivityClicked} className="bg-primary-gray hover:shadow px-4 py-2 rounded-full text-sm text-red-100 font-bold cursor-pointer leading-tight flex items-center transition up-on-hover">
                                Post your own activity
                                <ArrowRightAlt fontSize="small" />
                            </button>
                        </div>


                        <Tooltip unmountHTMLWhenHide={false} trigger="click" distance={20} interactive position="bottom-start" html={(
                            <div className="p-2">
                                <iframe title="wolverscreens" className="cursor-default" width={isMobile ? "320" : "480"} height={isMobile ? "160" : "270"} src={this.state.studentUS ? "https://reachcm.com/t/?whqa1s44n9" : "https://reachcm.com/t/?fneg5v2aw2"} />
                                <a className="mt-2  text-gray-500 hover:text-gray-600 transition flex justify-center" target="_blank" rel="noopener noreferrer" href={Routes.external.submitWolverscreenAd}>Submit your own slide</a>
                            </div>
                        )} theme="light">
                            <button className="mt-2 inline-flex items-center font-light text-gray-800 px-4 py-2 bg-white rounded shadow-sm">
                                WolverScreens Announcements
                                <ArrowDropDown />
                            </button>
                        </Tooltip>

                        <div className="pt-8 -my-10">

                            {this.state.upcomingActivities.length < 1 ? (
                                <div>
                                    <p className="mt-8 font-medium text-xl text-gray-600 text-center">No activities found...</p>
                                </div>
                            ) :
                                this.state.upcomingActivities.map((activity, i) => {
                                    return (
                                        <div className=" my-10" key={i}>
                                            <Activity uid={this.props.uid} type="upcoming" activity={activity} />
                                        </div>
                                    )
                                })}
                        </div>

                    </div>
                </div>
            </div >
        )
    }
}

class CalendarModal extends Component {
    constructor(props) {
        super(props);

        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.props.hideCalendar();
        }
    }

    render() {
        return (
            <div className="z-0 bg-darken h-screen top-0 w-screen fixed flex justify-center items-center">
                <div className="px-6 max-w-3xl w-full">
                    <div className="z-40 bg-white rounded-lg shadow-lg p-10" ref={this.setWrapperRef}>
                        <div className="flex justify-end pb-5">
                            <button className="flex items-center justify-center px-4 py-2 rounded-full text-black bg-gray-100 hover:shadow up-on-hover hover:bg-gray-200 transition" onClick={this.props.hideCalendar}>
                                <CloseOutlined fontSize="small" />
                                <span className="ml-1">Close</span>
                            </button>
                        </div>

                        <Calendar />
                    </div>
                </div>
            </div>
        );
    }
};

export default HomePage;