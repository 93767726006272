import React from 'react';
import Routes from '../components/routes';
import logo from "../assets/logo-100.png"
import { School, ViewList } from '@material-ui/icons';

const EmptyNavbar = () => {
    return (
        <div className="bg-navbar">
            <div className="max-w-3xl px-6 mx-auto py-6">
                <div className="flex items-center justify-between">

                    <div className="up-on-hover transition">
                        <a href={Routes.home} className="flex items-center hover:text-white">
                            <img src={logo} alt="Logo" className="h-8 mr-2 md:mr-4" />
                            <div className="">
                                <p style={{ fontSize: '.5rem' }} className="font-light tracking-widest leading-none uppercase text-gray-400">Harvard-Westlake</p>
                                <p className="text-xl md:text-2xl leading-tight tracking-tight font-semibold brand text-gray-300 tracking-wider leading-none -mb-1">The Quad</p>
                            </div>
                        </a>
                    </div>
                    <div className="flex items-center">
                        <a rel="noopener noreferrer" href="https://www.hw.com/life-at-hw/Student-Clubs" target="_blank" className={"up-on-hover transition flex items-center mx-1 md:mx-2 px-1 md:px-3 py-1 md:py-3 font-light rounded-md text-sm text-gray-400 hover:text-white"}>
                            <ViewList />
                            <span className="ml-2 hidden md:block">HW Clubs</span>
                        </a>

                        <a rel="noopener noreferrer" href="https://docs.google.com/spreadsheets/d/17pqQIvN7JuuqEgtl_QjeAlhNKn6vXKVWQqHrjCNx8IE/edit?usp=sharing" target="_blank" className={"up-on-hover transition flex items-center mx-1 md:mx-2 px-1 md:px-3 py-1 md:py-3 font-light rounded-md text-sm text-gray-400 hover:text-white"}>
                            <School />
                            <span className="ml-2 hidden md:block">College Rep Visits</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EmptyNavbar;