import React, { Component } from 'react';
import { colors, icons } from './linkConstants'
import DateTime from "react-datetime";
import moment from "moment";
import Link from "./Link";

class LinkSelector extends Component {
    constructor(props) {
        super(props);

        this.state = {
            links: []
        }
    }

    addLink = () => {
        const links = this.state.links;

        links.push({
            name: "",
            url: "",
            color: "",
            type: "",
            expiration: moment(new Date("01/01/2100")).unix(),
            newTab: true
        })

        this.setState({ links })
        this.props.setLinks(links);
    }

    deleteLink = (index) => {
        const links = this.state.links;

        // delete link
        links.splice(index, 1)

        this.setState({ links })
        this.props.setLinks(links);
    }

    handleChange = (index, e) => {
        const links = this.state.links;

        links[index][e.target.name] = e.target.value

        this.setState({ links })
        this.props.setLinks(links);
    }

    handleDateChange = (index, date) => {
        const links = this.state.links;

        links[index].expiration = moment(date).unix(date);

        this.setState({ links })
        this.props.setLinks(links);
    }

    render() {
        return (
            <div>
                <button type="button" className="px-4 py-1 bg-blue-500 rounded-full font-bold uppercase text-white hover:bg-blue-700 transition" onClick={this.addLink}>Add link</button>

                <div className="">
                    {this.state.links.map((link, index) => {
                        return (
                            <div key={index} className="my-8 bg-gray-300 p-5 rounded-lg">
                                <div className="mb-4 p-3 flex bg-white items-center" mb-5>
                                    <div className="flex-1 flex items-center">
                                        <p className="text-gray-700 font-semibold uppercase text-sm mr-2">Preview: </p>
                                        <Link link={link} />
                                    </div>
                                    <div className="justify-end">
                                        <button type="button" onClick={() => { this.deleteLink(index) }} className="bg-red-700 text-white font-semibold px-4 py-1 rounded-full hover:bg-red-800 transition">Delete link</button>
                                    </div>
                                </div>

                                <div className="flex my-5">
                                    <div className="flex-1 flex">
                                        <div className="flex items-center">
                                            <p className="mr-4">Color:</p>
                                            <select required name="color" onChange={(e) => this.handleChange(index, e)}>
                                                <option disabled selected value=""> -- select an option -- </option>
                                                {Object.keys(colors).map((key, i) => {
                                                    return key !== "read-more" ? <option key={i} value={key}>{key}</option> : ""
                                                })}
                                            </select>
                                        </div>

                                        <div className="ml-3 flex items-center">
                                            <p className="mr-4">Type:</p>
                                            <select required name="type" onChange={(e) => this.handleChange(index, e)}>
                                                <option disabled selected value=""> -- select an option -- </option>
                                                {Object.keys(icons).map((text, i) => {
                                                    return text !== "read-more" ? <option key={i} value={text}>{text}</option> : ""
                                                })}
                                            </select>
                                        </div>

                                        {/* <div className="ml-3 flex items-center">
                                            <p className="mr-1">Link expiration:</p>
                                            <DateTime value={moment.unix(this.state.links[index].expiration).toDate()} onChange={(date) => this.handleDateChange(index, date)} />
                                            <button type="button" className="ml-1 text-gray-300 hover:bg-teal-700 transition leading-none text-xs p-1 rounded-md bg-teal-500 cursor-pointer" onClick={() => this.handleDateChange(index, new Date("01/01/2100"))}>Doesn't expire?</button>
                                        </div> */}
                                    </div>
                                </div>

                                <input name="name" required onChange={(e) => this.handleChange(index, e)} placeholder="Name (Video call, Livestream, Submission, Form, etc.)" className="w-full my-1 border focus:border-black transition px-4 py-2" />
                                <input name="url" required onChange={(e) => this.handleChange(index, e)} placeholder="Url (MUST INCLUDE 'https://')" className="w-full my-1 border focus:border-black transition px-4 py-2" />
                            </div>
                        )
                    })}
                </div>
            </div>
        );
    }
}

export default LinkSelector;