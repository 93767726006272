import React, { Component } from 'react';
import { db, analytics } from '../components/firebase';
import { isAdmin } from '../helpers'
import LoadingScreen from '../components/LoadingScreen';
import ErrorPage from './ErrorPage';
import moment from 'moment';
import Navbar from '../components/Navbar';
import LinkSelector from "../components/LinkSelector"
import TimeSelector from '../components/TimeSelector';
import Routes from '../components/routes';
import { toast } from 'react-toastify';
import { Tooltip } from 'react-tippy';

class NewActivityPage extends Component {
    constructor(props) {
        super(props)

        this.state = {
            admin: null,
            links: [],
            timestamp: {
                startDate: null,
                endDate: null
            },
            loading: false
        }

        analytics.logEvent("new_activity_page", {
            uid: this.props.uid
        });
    }

    async componentDidMount() {
        this.setState({ admin: await isAdmin(this.props.uid) })
    }

    setDates = (timestamp) => {
        this.setState({
            timestamp
        })
    }

    setLinks = (links) => {
        this.setState({ links })
    }

    handleSubmit = async (e) => {
        e.preventDefault();

        // SUBMIT ALREADY CLICKED

        if (this.state.loading) return;

        // CONFIRMATION
        const confirm = window.confirm("Are you sure you want to submit?")
        if(!confirm) return;

        // TIMESTAMPS (START/END) EMPTY

        if (this.state.timestamp.startDate == null || this.state.timestamp.endDate == null) {
            alert("Please fill in the dates.")
            return
        }

        // EVENT ENDS BEFORE IT STARTS
        if (this.state.timestamp.endDate <= this.state.timestamp.startDate) {
            alert("The activity cannot end before or at the same time that it starts.")
            return
        }

        // LINKS HAVE EXPIRATION

        let linksExpirationsFilled = true
        this.state.links.forEach((link) => {
            if (link.expriation === "") {
                linksExpirationsFilled = false
                return
            }
        })

        if (!linksExpirationsFilled) {
            alert("Please fill all the expiration dates for links")
            return
        }

        // HTTPS / HTTP CHECK

        let re = new RegExp("^(http|https)://", "i");
        let linksBeginWithPrefix = true

        this.state.links.forEach((link) => {
            if (!re.test(link.url)) {
                linksBeginWithPrefix = false
                return
            }
        })

        if (!linksBeginWithPrefix) {
            alert("Please make sure all the URLs begin with https:// or http://")
            return
        }

        // PROCEED TO ADD
        analytics.logEvent("new_activity", {
            uid: this.props.uid,
            linkCount: this.state.links.length,
            emailReminder: e.target.emailReminder.checked,
            liveActivity: e.target.liveActivity.checked,
            hostName: e.target.hostName.value,
            middleSchoolActivity: JSON.stringify(e.target.middleSchoolActivity.checked),
            upperSchoolActivity: JSON.stringify(e.target.upperSchoolActivity.checked),
        });

        this.setState({ loading: true })

        const activity = {
            title: e.target.title.value,
            description: e.target.description.value,
            reviewed: false,
            links: this.state.links,
            students: [],
            middleSchoolActivity: e.target.middleSchoolActivity.checked,
            upperSchoolActivity: e.target.upperSchoolActivity.checked,
            emailReminder: e.target.emailReminder.checked,
            liveActivity: e.target.liveActivity.checked,
            host: {
                name: e.target.hostName.value,
                email: e.target.hostEmail.value
            },
            creator: this.props.uid,
            timestamp: {
                start: this.state.timestamp.startDate,
                end: this.state.timestamp.endDate,
                created: moment().unix()
            },
        }

        db.collection("activities").add(activity).then((docRef) => {
            alert("Success. Activity added. An admin will review your submission.");
            console.log(activity);
            window.location.href = "/activity/" + docRef.id
        }).catch((e) => {
            console.log(e);
            alert("Something went wrong. Please try and resubmit. Email airfan2@hwemail.com if you think there is an issue.")
        })
    }

    render() {
        return (
            <div className="bg-gray-lt">
                <Navbar />

                <div className="container mx-auto px-6">
                    <div className="mt-8 mb-5 text-center">
                        <p className="text-4xl font-semibold text-black leading-tight">Post an activity to The Quad</p>
                        <p className="text-gray-500 mt-2">Questions? Email <Tooltip trigger="click" size="small" position="bottom" distance="10" title="airfan2@hwemail.com"><span className="text-gray-700 cursor-pointer up-on-hover">Aariz Furniturewala '23</span></Tooltip></p>
                    </div>

                    <form className="pb-16" onSubmit={this.handleSubmit}>
                        <div className="flex justify-center">
                            <div className="bg-white rounded-lg p-8 shadow-xs transition inline-flex">
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/6LMut6ECWmw" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                        </div>

                        <div className="bg-white rounded-lg p-8 shadow-xs transition mt-5 ">
                            <p className="text-lg font-bold text-gray-700 upppercase">Basic information</p>

                            <input autoComplete="off" required name="title" placeholder="Title of event" className="bg-gray-200 my-4 w-full flex rounded-md transition focus:border-black focus:bg-gray-100 px-6 py-4" />
                            <input autoComplete="off" required name="hostName" placeholder="Host name (Prefect Council, BLACC, Chess Club)" className="bg-gray-200 my-4 w-full flex rounded-md transition focus:border-black focus:bg-gray-100 px-6 py-4" />
                            <input autoComplete="off" required name="hostEmail" placeholder="Host support email for questions (must be HW email)" className="bg-gray-200 my-4 w-full flex rounded-md transition focus:border-black focus:bg-gray-100 px-6 py-4" />
                            <textarea name="description" placeholder="Description (line breaks are disregarded)" rows="5" className="bg-gray-200 my-4 w-full flex rounded-md transition focus:border-black focus:bg-gray-100 px-6 py-4" />
                            <div className="flex items-center">
                                <input type="checkbox" defaultChecked="true" className="mr-2" name="emailReminder" />
                                <p>Email reminder before activity starts? (typically for live events)</p>
                            </div>
                            <div className="flex items-center mt-2">
                                <input type="checkbox" defaultChecked="true" className="mr-2" name="liveActivity" />
                                <p>Is this a live activity? (typically much shorter)</p>
                            </div>
                            <div className="flex items-center mt-2">
                                <input type="checkbox" className="mr-2" name="middleSchoolActivity" />
                                <p>Should this be shown to Middle School students?</p>
                            </div>
                            <div className="flex items-center mt-2">
                                <input type="checkbox" className="mr-2" defaultChecked="true" name="upperSchoolActivity" />
                                <p>Should this be shown to Upper School students?</p>
                            </div>
                        </div>

                        <div className="bg-white rounded-lg p-8 shadow-xs transition mt-5">
                            <p className="text-lg font-bold text-gray-700 upppercase">Timing</p>
                            <TimeSelector setDates={this.setDates} />
                        </div>

                        <div className="bg-white rounded-lg p-8 shadow-xs transition mt-5">
                            <p className="text-lg font-bold text-gray-700 upppercase">Links</p>
                            <p className="mb-2 text-xl text-gray-600">Only include external links</p>
                            <LinkSelector setLinks={this.setLinks} links={this.state.links} />
                        </div>

                        <button type="submit" className={"rounded-lg mt-5 w-full text-lg font-semibold flex justify-center text-white flex bg-indigo-600 hover:bg-indigo-500 px-4 py-5 transition " + (this.state.loading ? "cursor-not-allowed " : "cursor-pointer ")}>{this.state.loading ? "Loading..." : "Submit for admin approval"}</button>
                        <p className="text-red-600 font-semibold mt-3">* Your submission will be looked over by an admin before approval</p>
                    </form>
                </div>
            </div>
        )
    }
}

export default NewActivityPage;