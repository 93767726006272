import React, { Component } from 'react';
import Navbar from '../components/Navbar';
import { isAdmin } from '../helpers';
import ErrorPage from './ErrorPage';
import LoadingScreen from '../components/LoadingScreen';
import { auth, db } from '../components/firebase';


class ReviewActivitiesPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            admin: null,
            activities: []
        }

        this.getActivities()
    }

    async componentDidMount() {
        const admin = await isAdmin(auth.currentUser.uid)
        this.setState({ admin });
    }

    getActivities = async () => {
        db.collection("activities").where("reviewed", "==", false).orderBy("timestamp.created", "asc").onSnapshot((querySnapshot) => {
            let activities = [];

            querySnapshot.forEach(function (doc) {
                const data = doc.data()
                data.id = doc.id;
                activities.push(data)
            })

            this.setState({ activities });
        })


    }

    render() {
        switch (this.state.admin) {
            case null:
                return <LoadingScreen />
            case false:
                return <ErrorPage type="admin" />
            default:
                break;
        }

        const { activities } = this.state

        // activities.sort((a, b) => a.upperSchoolActivity.localeCompare(b.upperSchoolActivity));

        const sorted = []

        activities.forEach(activity => {
            if(activity.upperSchoolActivity) sorted.unshift(activity)
            else sorted.push(activity)
        });

        return (
            <div className="min-h-screen bg-gray-lt">
                <Navbar />
                <div className="pt-8 max-w-3xl mx-auto px-6 pb-10">
                    <p className="text-3xl font-semibold">Review activities page</p>
                    <p className="text-red-700">Please only review the activities that are for your campus. Keep the reasons (when rejecting) very short.</p>

                    <div>
                        <p className="font-semibold text-gray-600 mt-4">{activities.length} activit{activities.length === 1 ? "y" : "ies"} to review</p>
                        {sorted.map((activity) => {
                            const campuses = []
                            if (activity.upperSchoolActivity) campuses.push("Upper School")
                            if (activity.middleSchoolActivity) campuses.push("Middle School")
                            return (
                                <div className="bg-white hover:bg-gray-100 transition up-on-hover cursor-pointer p-3 rounded-lg shadow-xs hover:shadow mt-4" onClick={() => window.open("/activity/" + activity.id)}>
                                    <p className="font-medium text-gray-700">{activity.title} <span className="text-sm text-gray-500">({campuses.join(", ")})</span></p>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        );
    }
}

export default ReviewActivitiesPage;