import React, { Component } from 'react';
import Routes from './routes';
import { HomeOutlined, Person, ExitToApp, AccessTimeOutlined, ImportContactsOutlined, Domain, Add, QuestionAnswer, NewReleases, ViewList, School } from '@material-ui/icons/';
import { Tooltip } from 'react-tippy';
import logo from "../assets/logo-100.png"
import { auth, db } from "./firebase"
import { toast } from 'react-toastify';
import { isAdmin } from '../helpers';

class Navbar extends Component {
    constructor(props) {
        super(props)

        this.state = {
            admin: false,
            userCampus: null
        }
    }

    async componentDidMount() {
        const uid = auth.currentUser.uid

        const admin = await isAdmin(uid)
        this.setState({ admin })

        const doc = await db.collection("users").doc(uid).get()
        const userCampus = doc.data().campus

        this.setState({ userCampus });
    }

    getLinkClass = (route) => {
        const currentRoute = "/" + window.location.href.split("/").splice(3).join("/")
        if (route === currentRoute) {
            return "bg-navbar-item-active text-white"
        } else {
            return "text-gray-400 hover:text-white"
        }
    }

    signOut = () => {
        window.location.href = Routes.auth.signOut
    }

    requestCampusSwitch = async () => {
        const uid = await auth.currentUser.uid

        const userData = await (await db.collection("users").doc(uid).get()).data()

        // const requested = userData.requestingCampusSwitch

        // if (requested === true) {
        //     toast.error("Campus request is currently waiting for admin approval.", {
        //         position: toast.POSITION.BOTTOM_LEFT,
        //         closeOnClick: true
        //     });

        //     return;
        // }

        // first time user requesting new campus

        await db.collection("users").doc(uid).update({
            // campusReviewed: false,
            campus: userData.campus === "US" ? "MS" : "US"
            // requestingCampusSwitch: true
        })

        toast.success("Success. Campus changed. Please reload.", {
            position: toast.POSITION.BOTTOM_LEFT,
            closeOnClick: true
        });


    }

    render() {
        return (
            <div>
                <div className="bg-navbar">
                    <div className="max-w-3xl px-6 mx-auto py-6">
                        <div className="flex items-center">

                            <div className="flex-1 up-on-hover transition">
                                <a href={Routes.home} className="flex items-center hover:text-white">
                                    <img src={logo} alt="Logo" className="h-8 mr-2 md:mr-4" />
                                    <div className="">
                                        <p style={{ fontSize: '.5rem' }} className="font-light tracking-widest leading-none uppercase text-gray-400">Harvard-Westlake</p>
                                        <p className="text-xl md:text-2xl leading-tight tracking-tight font-semibold brand text-gray-300 tracking-wider leading-none -mb-1">The Quad</p>
                                    </div>
                                </a>
                            </div>


                            <div className="-mx-2 flex items-center">
                                <Link route={Routes.home} icon={<HomeOutlined fontSize="small" />} text={"Home"} getLinkClass={this.getLinkClass} />

                                <Link route={Routes.bulletin.ongoing} icon={<AccessTimeOutlined fontSize="small" />} text={"Ongoing"} getLinkClass={this.getLinkClass} />

                                <Link route={Routes.bulletin.resources} icon={<ImportContactsOutlined fontSize="small" />} text={"Resources"} getLinkClass={this.getLinkClass} />

                                <div className="h-8 w-px bg-gray-800 rounded"></div>

                                <Link route={Routes.admin.newActivityPage} icon={<Add fontSize="small" />} text={"Post activity"} getLinkClass={this.getLinkClass} />

                                <div className="flex text-white mx-2">
                                    <Tooltip animation="fade" interactive="true" theme="light" distance="20" position="bottom" trigger="click" html={<UserDropdown campus={this.state.userCampus} admin={this.state.admin} requestCampusSwitch={this.requestCampusSwitch} signOut={this.signOut} />}>
                                        <div className="cursor-pointer flex items-center">
                                            <Person />
                                        </div>
                                    </Tooltip>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const UserDropdown = (props) => {
    return (
        <div className="p-2">
            {props.admin ? (
                <div>
                    <a className="w-full mb-2 cursor-pointer text-gray-700 font-medium hover:text-black transition rounded-md flex text-sm items-center" href={Routes.admin.reviewActivities}>
                        <div>
                            {/* <span className="text-xs font-bold text-gray-400 uppercase">Admin</span> */}
                            <p className="ml-2">Admin review</p>
                        </div>
                    </a>

                    <div className="h-px my-4 rounded-full bg-gray-400"></div>
                </div>
            ) : null}

            <div>
                <a className="w-full mb-2 cursor-pointer text-gray-700 font-medium hover:text-black transition rounded-md flex text-sm items-center" href={Routes.admin.newActivityPage}>
                    <Add fontSize="small" />
                    <div>
                        {/* <span className="text-xs font-bold text-gray-400 uppercase">Admin</span> */}
                        <p className="ml-2">New activity</p>
                    </div>
                </a>

                <div className="h-px my-4 rounded-full bg-gray-400"></div>
            </div>

            <a target="_blank" rel="noopener noreferrer" className="w-full mb-2 cursor-pointer text-gray-700 font-medium hover:text-black transition rounded-md flex text-sm items-center" href="https://docs.google.com/spreadsheets/d/1ET0naWCDdlEDDUBDLlSTGnAx9xTP5pz-m_1nsFAHbiA/edit?usp=sharing">
                <ViewList fontSize="small" />
                <span className="ml-2">View HW Clubs</span>
            </a>
            
            <a target="_blank" rel="noopener noreferrer" className="w-full mb-2 cursor-pointer text-gray-700 font-medium hover:text-black transition rounded-md flex text-sm items-center" href="https://docs.google.com/spreadsheets/d/17pqQIvN7JuuqEgtl_QjeAlhNKn6vXKVWQqHrjCNx8IE/edit?usp=sharing">
                <School fontSize="small" />
                <span className="ml-2">College Rep Visits</span>
            </a>

            <button className="w-full mb-2 cursor-pointer text-gray-700 font-medium hover:text-black transition rounded-md flex text-sm items-center" onClick={props.requestCampusSwitch}>
                <Domain fontSize="small" />
                <span className="ml-2">Switch to {props.campus === "US" ? "Middle School" : "Upper School"}</span>
            </button>

            <button className="w-full mb-2 cursor-pointer text-gray-700 font-medium hover:text-black transition rounded-md flex text-sm items-center" onClick={props.signOut}>
                <ExitToApp fontSize="small" />
                <span className="ml-2">Sign out</span>
            </button>
            <Tooltip title="airfan2@hwemail.com" size="small" position="bottom-start" trigger="click" arrow distance="0">
                <button className="w-full cursor-pointer text-gray-500 font-medium hover:text-black transition rounded-md flex text-xs items-center">
                    <QuestionAnswer fontSize="small" />
                    <span className="ml-2">Questions? Email Aariz.</span>
                </button>
            </Tooltip>
        </div>
    )
}

const Link = (props) => {
    return (
        <a rel="noopener noreferrer" href={props.route} target={props.newTab ? "_blank" : null} className={"up-on-hover transition flex items-center mx-1 md:mx-2 px-1 md:px-3 py-1 md:py-3 font-light rounded-md text-sm " + props.getLinkClass(props.route)}>
            {props.icon}
            <span className="ml-2 hidden md:block">{props.text}</span>
        </a>
    )
}

export default Navbar;