import React, { Component } from 'react';
import {
    BarLoader

} from "react-spinners";

class LoadingScreen extends Component {
    render() {
        return (
            <div className="h-screen w-screen flex justify-center items-center bg-gray-100">
                <BarLoader
                    height={4}
                    width={120}
                    color={"#cc0000"}
                />
            </div>
        )
    }
}

export default LoadingScreen;