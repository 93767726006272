import React, { Component } from 'react';
import Navbar from '../components/Navbar';
import bulletinInfo from "../components/bulletinInfo"
import { ArrowRightAlt } from '@material-ui/icons';
import { db, analytics, auth } from '../components/firebase';
import moment from 'moment';
import LoadingScreen from '../components/LoadingScreen';
import qs from "qs"
import Scroll from "react-scroll"
import Routes from '../components/routes';

var Element = Scroll.Element
var scroller = Scroll.scroller

class BulletinPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activities: null,
            location: qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).section
        }

        analytics.logEvent("bulletin_page")

        this.getActivities()
    }

    getActivities = async () => {
        const nowUnix = moment().unix()
        const activities = []
        
        const userData = await (await db.collection("users").doc(auth.currentUser.uid).get()).data()
        const userCampus = userData.campus === "US" ? "upperSchoolActivity" : "middleSchoolActivity"

        const querySnapshot = await db.collection("activities").where(userCampus, "==", true).where("timestamp.end", ">", nowUnix).where("liveActivity", "==", false).where("reviewed", "==", true).orderBy("timestamp.end", "desc").get()
        querySnapshot.forEach((docRef) => {
            const activity = docRef.data()
            console.log(docRef.data())
            if (activity.timestamp.start < nowUnix) {
                activities.push({
                    title: activity.title,
                    url: "/activity/" + docRef.id,
                    newTab: false,
                    until: moment.unix(activity.timestamp.end).fromNow(),
                    host: activity.host
                })
            }
        })

        this.setState({ activities }, () => {
            this.scrollIfNeeded()
        });
    }

    scrollIfNeeded = () => {
        if (typeof this.state.location === "undefined") return
        // window.location.hash = '#' + this.state.location
        if (this.state.location === "ongoing") {
            // scroller.scrollTo('ongoing', {
            //     duration: 2000,
            //     smooth: "easeInOutQuint"
            //     // ease
            // })
        } else {
            scroller.scrollTo('resources', {
                duration: 2000,
                smooth: "easeInOutQuint"
                // ease
            })
        }
    }

    render() {
        if (this.state.activities == null) {
            return <LoadingScreen />
        }

        return (
            <div className=" bg-graphics min-h-screen h-full">
                <Navbar />

                <div className="bg-primary-red-dark py-16 shadow-lt bg-fixed">
                    <div className="max-w-3xl px-6 mx-auto">
                        <div>
                            <p className="text-3xl md:text-4xl leading-tight font-semibold text-white">Bulletin</p>
                            <p className="md:text-lg font-light text-gray-400 leading-tight mt-1">A hub for long-term, ongoing activities, and other fun and important resources.</p>
                        </div>

                    </div>
                </div>

                <div className="pt-8 max-w-3xl mx-auto px-6 pb-20" id="ongoing">

                    <Element name="ongoing">
                        <div className={"rounded-lg shadow-lg p-10 mb-10 bg-white " + (this.state.location === "ongoing" ? " border border-gray-600 shadow-lg" : " shadow-md")}>
                            <div className="">
                                <p className={"uppercase font-bold tracking-widest text-sm" + (this.state.location === "ongoing" ? " text-gray-700" : " text-gray-500")}>Ongoing activities</p>
                            </div>


                            {this.state.activities.length === 0 ? (
                                <div className=" mt-2">
                                    <p className=" text-gray-500 font-light">No ongoing activities right now.</p>
                                </div>
                            ) : (
                                    <div className="-my-4 py-4">
                                        {this.state.activities.map((item, i) => {
                                            return (
                                                <div className="my-4" id={i}>
                                                    <BulletinItem item={item} />
                                                </div>
                                            )
                                        })}
                                    </div>
                                )
                            }
                        </div>
                    </Element>


                    <Element name="resources">
                        <div className={"mt-10 rounded-lg p-10 mb-10 bg-white " + (this.state.location === "resources" ? " border border-gray-600 shadow-lg" : " shadow-md")} id="resources">
                            <div className="mb-8">
                                <p className={"uppercase font-bold tracking-widest text-sm" + (this.state.location === "resources" ? " text-gray-700" : " text-gray-500")}>Resources</p>
                            </div>

                            <div className="-my-4">
                                {bulletinInfo.map((item, i) => {
                                    return (
                                        <div className="my-4" id={i}>
                                            <BulletinItem item={item} />
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </Element>
                </div>
            </div>
        );
    }
}

const BulletinItem = (props) => {
    const handleClick = () => {
        analytics.logEvent("bulletin_item_clicked", {
            title: props.item.title,
            url: props.item.url
        })

        if (props.item.newTab !== false) {
            window.open(props.item.url)
        } else {
            window.location.href = props.item.url
        }
    }

    return (
        <div className={"px-4 md:px-6 py-3 md:py-4 rounded-md up-on-hover shadow hover:shadow-md transition cursor-pointer" + (typeof props.item.until === "undefined" ? " text-gray-600 hover:text-gray-700 bg-gray-100 hover:bg-gray-200" : " border border-gray-100 hover:border-gray-200 bg-white")} onClick={handleClick}>
            <div className="font-light flex items-center">
                <div className="flex-1">
                    <p className={"" + (typeof props.item.until === "undefined" ? " text-base " : " font-medium text-gray-800 text-lg")}>
                        {props.item.title}
                    </p>
                    {typeof props.item.until === "undefined" ? "" : (
                        <div>
                            <p className="text-gray-500 text-sm font-light leading-tight">ends {props.item.until} • Hosted by {props.item.host.name}</p>
                        </div>
                    )}
                </div>

                <div class={"" + (typeof props.item.until === "undefined" ? " text-gray-500" : " text-gray-600")}>
                    <ArrowRightAlt />
                </div>
            </div>
        </div>
    );
}

export default BulletinPage;