import React, { Component } from 'react';
import Navbar from '../components/Navbar';
import { db } from '../components/firebase';
import LoadingScreen from '../components/LoadingScreen';
import ErrorPage from "../pages/ErrorPage";
import Activity from "../components/Activity"

class ActivityPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activity: null
        }

        this.getActivity()
    }

    getActivity = () => {
        const id = this.props.match.params.id
        let activity

        db.collection("activities").doc(id).get().then((docRef) => {
            if (docRef.exists) {
                activity = docRef.data()
                activity.id = docRef.id;
                activity.signedUp = activity.students.includes(this.props.uid)
            } else {
                activity = false
            }

            this.setState({ activity })
        }, (e) => {
            console.error(e);
        })
    }

    render() {
        switch (this.state.activity) {
            case null:
                return <LoadingScreen />
            case false:
                return <ErrorPage type="activity_not_found" />
            default:
                break
        }

        return (
            <div className=" min-h-screen h-full">
                <Navbar />

                <Activity uid={this.props.uid} type="page" activity={this.state.activity} />
            </div>
        );
    }
}

export default ActivityPage;