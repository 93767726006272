// package manager imports
import React, { Component } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import { Tooltip } from 'react-tippy';
import { ToastContainer } from 'react-toastify'

// internal imports
import { auth, provider, db, analytics } from "./components/firebase"
import firebase from "firebase"
import Routes from "./components/routes"
import { isAdmin } from "./helpers"

// components
import LoadingScreen from "./components/LoadingScreen"

// pages
import HomePage from "./pages/HomePage"
import SignInPage from "./pages/SignInPage"
import NewActivityPage from "./pages/NewActivityPage"
import ActivityPage from './pages/ActivityPage';
import ErrorPage from './pages/ErrorPage';
import BulletinPage from "./pages/BulletinPage";
import SelectCampusPage from "./pages/SelectCampusPage";
import StudentListViewPage from './pages/StudentListViewPage';
import CalendarPage from "./pages/CalendarPage"

// css
import 'react-tippy/dist/tippy.css';
import './stylesheets/react-datetime.css';
import "react-big-calendar/lib/css/react-big-calendar.css";
import 'react-toastify/dist/ReactToastify.css';
import ReviewActivitiesPage from './pages/ReviewActivitiesPage';


class App extends Component {
    constructor(props) {
        super(props)

        this.state = {
            uid: null,
            userCampus: null, //null = not loaded, false = no campus selected, "MS" = middle school, "US" = upper school
            userIsHW: null,
        }

        this.listenForAuth()
    }

    emailIsHW = async (user) => {
        if (!user.email.split("@")[1].match(/hw(email)?[.]com/) && !(await isAdmin(user.uid))) {
            return false
        } else {
            return true
        }
    }

    deleteUserData = async (uid) => {
        await db.collection("users").doc(uid).delete()
    }

    getUserCampus = async (uid) => {
        const docRef = await db.collection("users").doc(uid).get()

        if (!docRef.exists) return false;

        const userData = docRef.data();

        if (typeof userData.campus === "undefined" || (userData.campus !== "US" && userData.campus !== "MS")) { //should never happen, but handles error if so
            await this.deleteUserData(uid)
            window.location.reload()
        }

        return userData.campus;
    }

    listenForAuth = () => {
        auth.onAuthStateChanged(async (user) => {
            if (user) {
                if (!(await this.emailIsHW(user))) {
                    this.setState({ userIsHW: false })
                    this.signOut()
                } else {
                    const uid = user.uid
                    const userCampus = await this.getUserCampus(uid)

                    this.setState({ userCampus, uid })
                }
            } else {
                this.setState({ uid: false })
            }
        });
    }

    parentSetState = (obj) => { this.setState(obj) }

    signIn = async () => {
        try {
            const res = await auth.signInWithRedirect(provider)

            analytics.logEvent("signin")

            this.setState({ uid: res.user.uid })
        } catch (e) {
            console.error(e);
        }
    }

    signOut = () => {
        this.setState({ uid: false })

        auth.signOut().then(() => {
            const currentUrl = "/" + window.location.href.split("/").splice(3).join("/")

            if (currentUrl === Routes.auth.signOut) {
                window.location.href = "/"
            }
        }).catch(function (error) {
            window.location.href = "/"
            console.log(error)
        })
    }

    render() {

        if(window.location.pathname === Routes.calendar) {
            return <><CalendarPage /><Footer /></>
        }

        if (this.state.userIsHW === false) {
            return <ErrorPage parentSetState={this.parentSetState} type="not-hw" />
        }

        switch (this.state.uid) {
            case null:
                return <LoadingScreen />
            case false:
                return <SignInPage signIn={this.signIn} />
            default:
                break
        }

        switch (this.state.userCampus) {
            case null:
                return <LoadingScreen />
            case false:
                return <SelectCampusPage uid={this.state.uid} />
            default:
                break
        }

        return (
            <Router>
                <ToastContainer />

                <Switch>
                    <Route exact path={Routes.home} render={(props) => <HomePage userCampus={this.state.campus} uid={this.state.uid} {...props} />} />

                    {/* <Route exact path={Routes.auth.selectCampus} render={(props) => <SelectCampusPage userCampus={this.state.userCampus} uid={this.state.uid} {...props} />} /> */}

                    <Route exact path={Routes.admin.newActivityPage} render={(props) => <NewActivityPage {...props} uid={this.state.uid} />} />

                    <Route exact path={Routes.admin.reviewActivities} render={(props) => <ReviewActivitiesPage {...props} uid={this.state.uid} />} />

                    <Route exact path={Routes.admin.studentListView} render={(props) => <StudentListViewPage {...props} uid={this.state.uid} />} />

                    <Route exact path={Routes.bulletin.default} render={(props) => <BulletinPage userCampus={this.state.campus} {...props} />} />

                    <Route path={Routes.activityPage} render={(props) => <ActivityPage {...props} uid={this.state.uid} />} />

                    <Route exact path={Routes.auth.signOut} render={(props) => {
                        this.signOut()
                    }} />

                    <Route render={(props) => <ErrorPage type="404" />} />
                </Switch>

                <Footer />
            </Router>
        );
    }
}

const Footer = () => {
    return (
        <div>
            <div className="bg-primary-gray py-16">
                <div className="mx-auto px-6 max-w-3xl">
                    <p class="text-gray-400 font-bold text-lg brand">The Quad</p>
                    {/* <p class="text-gray-600 font-light text-sm mt-1 leading-tight">Developed by <span className="text-gray-200">Aariz Irfan '23</span> and <Tooltip size="small" title="Guidance from Amaan Irfan, Venture, & Prefect Council"><span className="text-gray-500">The Quad Team</span></Tooltip></p> */}
                    <p class="text-gray-600 font-light text-sm mt-1 leading-tight">Developed by <Tooltip size="small" title="Guidance from Amaan Irfan, Venture, & Prefect Council"><span className="text-gray-500">The Quad Team</span></Tooltip></p>
                </div>
            </div>
        </div>

    )
}

export default App;
